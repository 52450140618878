  // BIG ASSUMPTIONS
  .experiments {
    list-style: none;

    counter-reset: counter;

    .experiment {
      display: flex;
      position: relative;
      counter-increment: counter;
      width: 100%;

      &::before {
        position: absolute;
        top: -2px;
        left: 0;
        width: 26px;
        font-size: 14px;
        text-align: right;
        margin-top: 9px;
        content: counter(counter) ". ";
        z-index: 1;
      }

      .col__item {
        margin-left: 0;
        padding-right: 18px;
        padding-left: 30px;
        width: 100%;
        min-height: 48px;

        &.active-input {
          padding-bottom: 4px;
        }
      }
    }
  }
