.select-group {
  position: relative;
  pointer-events: all;
  background-color: $grayBg;

  @include space-between-center-v;
  align-items: flex-end;

  .text--weight-normal {
    margin-bottom: 3px;
    font-size: 14px;
  }

  &.fixed {
    position: absolute;
    top: 16px;
    left: 0;
  }

  &:after {
    position: absolute;
    pointer-events: none;
    top: 9px;
    right: 10px;

    width: 0;
    height: 0;

    content: '';

    border: 6px solid transparent;
    border-color: $black transparent transparent transparent;
  }

  .select {
    font-size: 14px;

    width: auto;
    margin-left: 10px;
    padding: 2px 24px 2px 10px;

    cursor: pointer;
    text-align: left;

    border: none;
    border: 1px solid transparent;
    border-radius: 0;
    outline: none;
    background-color: $white;
    box-shadow: 2px 2px 2px 0 rgba($black, .2);

    appearance: none;

    &--long {
      width: 180px;
    }
  }
}
