.tooltip {
  position: relative;
  display: inline-block;

  &:hover {
    .tooltip__box {
      display: block;
    }
  }

  &__box {
    font-size: 14px;
    font-weight: normal;
    color: $black;
    position: absolute;
    z-index: 5;
    top: -20%;
    left: 103%;
    // margin-top: -4px;

    display: none;

    width: 290px;
    padding: 10px;

    background-color: $white;
    border: 1px solid $grayDm;

    box-shadow: 2px 2px 2px 0px rgba($black, .2);

    // &:before {
    //   position: absolute;
    //   top: -10px;
    //   left: calc(50% - 10px);
    //   content: '';
    //   display: block;
    //   width: 0;
    //   height: 0;
    //   border-style: solid;
    //   border-width: 0 10px 10px 10px;
    //   border-color: transparent transparent $white transparent;
    // }
  }
}

//
.title-tooltip {
  max-width: 400px !important;

  &--wide {
    max-width: 600px !important;
  }
}
