.helper-tooltip {
    position: fixed;
    top: 68px;
    right: 50px; 
    padding: 20px;
    min-width: 450px;
    background-color: white;
    box-shadow: 2px 2px 9px 2px rgba(0, 0, 0, .2);
    z-index: 10;

    &__header {
        margin-bottom: 30px;
        text-align: center;
        font-size: 30px;
        font-weight: 500;
        color: #848484;
        border-bottom: 2px solid $orange;
    }

    &__shortcuts-list {
        width: 100%;
        height: 100%;

        .item {
            display: flex;
            padding-left: 4px;
            justify-content: space-between;
            align-items: center;
            
            &:not(:last-of-type) {
                border-bottom: 1px solid $gray;
            }

            .title {
                margin-right: auto;
                padding: 12px 0;
                font-size: 20px;
                line-height: 22px;
            }
        }
    }

    .close-icon {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }

    .space {
        padding: 0 5px;
    }
}

.keyboard-key {
    background: rgb(221,221,221);
    padding: 3px 8px;
    font-size: 15px;
    font-weight: 600;
    min-width: 30px;
    height: 27px;
    margin: 4px 2px;
    border-radius: 4px;
    color: rgb(29,28,29);
    --saf-0: rgba(29,28,29,0.5);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.4);
    text-shadow: none;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    line-height: 21px;
}
