.col {
  padding: 12px 0 6px 6px;

  background-color: $white;

  position: relative;
  height: 100%;
  @include column;

  &__wrapper {
    padding: 0;
    overflow-x: hidden;

    &.active {
      background-image: linear-gradient(
        180deg, 
        rgba(0, 0, 0, 0.030) 0%, 
        rgba(0, 0, 0, 0.015) 4px, 
        rgba(0, 0, 0, 0.015) calc(100% - 3px), 
        rgba(0, 0, 0, 0.030) 100%
      );
      
      .col--size-sm {
        background-color: transparent;
      }
    }

    .col {
      border: none;
      padding: 12px 0 6px;
      height: 100%;

      .col__item {
        width: 100%;
        overflow: visible;
      }

      .col__content {
        padding: 0;
        height: auto;
      }

      &:first-child {
        width: 308px;
        margin: 12px 6px 6px;
        height: auto;
        background-color: $grayBg;

        .col__title {
          .h4 {
            font-size: 16px;
            color: $grayDr;
          }
        }

        .col__content {
          min-height: 100px;

          .textarea {
            background: transparent;
          }
        }
      }
    }
  }

  &--scroll-x {
    overflow: auto;
  }

  &--size {
    &-sm {
      width: 320px;

      &.active {
        background-image: linear-gradient(
          180deg, 
          rgba(0, 0, 0, 0.030) 0%, 
          rgba(0, 0, 0, 0.015) 4px, 
          rgba(0, 0, 0, 0.015) calc(100% - 3px), 
          rgba(0, 0, 0, 0.030) 100%
        );
      }
    }

    &-md {
      width: 480px;
    }

    &-lg {
      width: 640px;
    }
    &-xl {
      width: 720px;
    }
  }

  &__cell {
    padding: 0 0 8px;
    width: 100%;

    &.has-min-height {
      min-height: 100px;
    }
  }

  &:last-child {
    border-right: none;
  }

  .tooltip__target {
    border-bottom: 1px dotted $grayMd;
    cursor: context-menu;

    &--top .tooltip__box {
      top: -34px;
    }

    &:hover {
      border-color: transparent;
    }
  }

  &__title {
    margin-bottom: 6px;

    & > .h4 {
      margin-left: 7px !important;
    }

    &--no-margin {
      margin-bottom: 0;
    }

    .h4,
    .h6 {
      margin: 0 6px;

      @include space-between-center-v;

      &.inline {
        @include space-between-start;
      }
    }

    &--start {
      .h4 {
        justify-content: start;
        align-items: flex-start;
      }
    }

    &--sticky {
      position: sticky;
      top: 0;
      margin-bottom: 0;
      padding: 12px 6px 0;
      z-index: 2;
     
      background-color: white;
    }
  }

  &__subtitle {
    padding: 2px 14px;

    &--bottom-gap {
      padding: 6px 0;
      margin: 0 6px 10px;
    }
   }

  &__content,
  &-notes {
    overflow-y: auto;
    height: 100%;
  }

  &__item-wrapper {
    position: relative;
    width: 100%;
  }

  &__item {
    position: relative;

    margin: 0;
    padding: 6px 18px 6px 14px;

    min-height: 31px;
    width: 100%;

    display: flex;
    align-items: flex-start;

    &.active-input {
      padding: 6px 18px 4px 14px;
      background-color: $white;
      box-shadow: inset 0px 5px 8px -5px rgba(0, 0, 0, 0.2),
      inset 0px -5px 8px -5px rgba(0, 0, 0, 0.2);
    }

    &--bordered {
      box-shadow: none !important;
      border: 2px solid $grayLt;
    }
  }

  .business-goal {
    .active-input {
      background-color: $grayWhite;
    }
  }

  .goal-note {
    position: relative;
    
    margin: auto 0 2px;
    padding: 6px 6px 6px 14px;
    width: 100%;
    min-height: 40px;
    line-height: 18px;
    resize: none;
    cursor: pointer;
    border: none;
    
    &.active-input {
      background-color: $white;
      box-shadow: inset 0px 5px 8px -5px rgba(0, 0, 0, 0.2),
      inset 0px -5px 8px -5px rgba(0, 0, 0, 0.2);
    }
  }

  // HIDDEN COMMITMENTS

  &__wb {
    position: relative;

    margin-bottom: 10px;
    margin-left: 6px;
    margin-right: 6px;
    padding: 6px 0 6px;
    background: $grayBg;

    .col__subtitle {
      color: $grayDr;

      &.h5 {
        cursor: pointer;
        margin: 6px 0 4px;
      }
    }

    .button--role-add {
      padding-left: 20px;
    }

    .wb__content {
      overflow-y: visible;

      min-height: 100px;

      .col__item {
        padding: 6px 18px 6px 20px;
        width: 100%;
  
        &.active-input {
          padding: 6px 18px 4px 20px;
          background-color: $grayWhite;
        }
      }
    }
  }

  &__sub {
    margin-top: 10px;
  }

  &-experiment {
    height: 100%;
    .col__title {
      .h4 {
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  }

  &.with-notes {
    display: flex;
    flex-direction: row;
    padding: 0;
  }

  &__rest-content {
    padding: 12px 0 6px;
    width: 100%;
    height: 100%;
  }
}

.experiment-wrapper {
  position: relative;
  width: min-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $white;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);

  .h6, 
  .col__subtitle .h4 {
    margin-left: 15px !important;
  }

  &:last-child {
    border: none;
  }

  .h5.col__subtitle {
    margin-left: 0;
  }

  &:not(:last-of-type) {
    margin-right: 9px;
  }

  .col {
    border: none;
    padding-left: 0;

    .col__item {
      padding: 6px 18px 6px 16px;
      min-height: 48px;

      &_small {
        min-height: 24px;
      }

      &.active-input {
        padding: 6px 18px 4px 16px;
        background-color: $white;
        box-shadow: inset 0px 5px 8px -5px rgba(0, 0, 0, 0.2),
        inset 0px -5px 8px -5px rgba(0, 0, 0, 0.2);
      }
    }
  }

  .col-challenge {
    height: 100%;
    margin-bottom: 0;
    padding: 0;
    justify-content: space-between;

    .col__content {
      max-height: 76%;
      overflow-y: auto;
    }
  }

  .col-notes {
    max-height: 20%;
    
    .col__cell {
      overflow-y: visible;
    }

    .col__item {
      width: auto;
      margin: 0 16px 10px 16px;
      min-height: auto;
      border: 1px solid $grayLt;
    }

    .col__title {
      position: sticky;
      background: white;
      z-index: 10;
      width: 100%;
      padding-bottom: 14px;
      margin: 0;
      right: 0;
      top: 0;
    }
  
    h6 {
      margin-right: 16px;
      padding-top: 4px;
    }
    .modal {
      top: initial;
      right: 40px;
    }
  }

  .col__content {
    overflow-y: visible;
  }

  &::-webkit-scrollbar-track {
    width: 6px;
    height: auto;
    background: #f3f3f3;
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;
    background: #b2bec3;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: auto;
    background-color: #000;
  }

  .button--role-add {
    padding-left: 16px;
  }
}

.experiment-title {
  min-height: 64px;
}

.col-experiment {
  padding-left: 0;
  height: auto;
  margin-bottom: 2px;
  flex-basis: 100%;
  background-color: $white;

  &:last-of-type {
    padding-top: 0;
  }

  .h4, .h6 {
    margin-left: 16px !important;
  }
  &__title {
    width: 50%;
    text-align: right;
    margin-left: 35px;
    font-weight: 700;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__topic {
    border: 0;
    text-align: center;
    width: auto;
    min-width: 250px;

    &::placeholder {
      font-weight: 400;
      opacity: .75;
      font-size: 14px;
    }

    &.filled {
      border-color: transparent;
    }
  }
  &__title-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &-prefix {
    text-align: right;

    &.spaced {
      padding-left: 5px;
    }
  }

  &-postfix {
    text-align: left;

    &.spaced {
      padding-left: 5px;
    }
  }

  &-title_full {
    text-align: center;
  }
}

.nff-input {
  text-align: center;
  width: auto;
  min-width: 250px;
  border: 0;
  font-weight: 700;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin: 0;

  &::placeholder {
    font-weight: 400;
    opacity: .75;
    font-size: 14px;
  }

  &.filled {
    border-color: transparent;
  }

  &__wrapper {
    position: relative;
    width: auto;
    
    &:after {
      content: '';
      position: absolute;
      bottom: -3px;
      width: 300px;
      right: calc(50% - 150px);
      margin-left: 150px;
      border-bottom: 1px solid $black;

    }

    &-left-aligned:after {
      bottom: 0;
      left: 0;
      right: initial;
      width: 100%;
    }

    &-center-aligned:after {
      right: 25%;
      width: 50%;
    }

    &__secondary:after {
      border-bottom: 1px solid $orange;
    }

    &.filled:after {border-bottom: 1px solid transparent;}
  }
}

.workout-header {
  &.design-experiments__header {
    padding: 6px 26px 16px;
  }
}

.col__radio-group {
  margin: 3px 0 !important;
}

.col__radio {
  margin: 0 16px;
}

.two-rows {
  .col__item {
    width: 100%;
    min-height: 48px;
  }
}

.list-column {
  &__header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px 26px 16px 16px;
    background-color: $white;
    border-bottom: 2px solid $gray;
  }
}
