*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;

  outline: none;
}

html,
body,
button {
  font-family: $arial !important;
  -webkit-font-smoothing: antialiased;
}

#root,
.main {
  height: 100%;
}

::-webkit-scrollbar {
  width: 0;

  background-color: $subWhite;
}

.link_disabled {
  pointer-events: none;
  opacity: .5;
}

.dragged {
  position: relative;
  width: 302px;
  margin: 2px 10px 4px;
  padding: 6px 12px 6px 6px;
  transition: .2s ease;
  cursor: pointer;
  background-color: #f3f3f3;
  z-index: 999;
}

.card-badge {
  display: none;
}

// circle for unread feedback
.unread {
  display: inline;
  font-size: 11px;
  margin-right: 5px;
  color: $blue;
}

.superscript {
  position: relative;
  margin-right: 6px;

  &--gap {
    margin-right: 10px;
  }

   &--copy:after{
    content: '©';
    position: absolute;
    font-size: 11px;
   }

  &:after {
    content: '®';
    position: absolute;
    font-size: 11px;
    height: 8px;
    top: 0px;
    line-height: 1;
  }
}

.beta {
  position: relative;
  margin-right: 8px;

  &:after {
    content: 'beta';
    position: absolute;
    font-size: 11px;
    height: 8px;
    top: 0px;
    line-height: 1;
    color: $orange;
  }
}

.table__actions {
  white-space: nowrap;
}

.ant-layout-content.main-content {
  padding: 20px;
  min-height: calc(100vh - 52px - 38px);

  &--auth {
    display: flex;
    padding-top: 50px;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
}

.page-loader {
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
}

/* RESET ANTD STYLES */
body {
  font-size: initial !important;
  line-height: initial !important;
  color: #000 !important;
}

ul, p {
  margin-bottom: 0 !important;
}

.h4, h3, h5, h6 {
  font-weight: 700 !important;
  color: #000;
}

.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mv-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.flex-wrapper {
  display: flex;

  &--column {
    @include column;
  }

  &--center-h {
    @include center-h;
  }
}

.quill-area {
  .ql-container {
    height: 350px;
  }
}

.ant-table-row-level-0 {
  cursor: pointer;
}

.ant-dropdown-link {
  margin-right: 20px;
}

.pointer {
  cursor: pointer;
}

.osano-cm-widget { display: none; }

.profile__drawer .ant-drawer-content-wrapper {
  max-width: 500px;
  width: 100% !important;
}

.card__tag {
  margin-bottom: 20px;
}

.sprint-card {
  margin-top: 20px !important;

  .ant-card-head {
    padding: 0 15px;
  }
  
  .ant-card-head-title {
    padding: 15px 15px 15px 0;
  }

  .ant-card-body {
    padding: 15px;
  }

  &__row {
    padding: 7px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: auto;
    background-color: black;
  }

  &::-webkit-scrollbar-track {
    width: 6px;
    height: auto;
    background: #f3f3f3;
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;
    background: $grayMd;
  }
}

.video {
  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__hint {
    font-size: 12px;
    font-weight: bold;
    margin: 5px 0 0 5px;
  }
}

.ant-dropdown-menu-root .ant-dropdown-menu-item .anticon:first-child {
  margin-right: 8px;
}

.ant-menu-overflow .ant-menu-overflow-item-rest svg {
  fill: $white;
}

.lang-de .dev-workout .list-column .col-experiment__title-wrapper h4 {
  font-size: 15px;
}

.protocol-box {
  height: calc(100vh - 200px); 
  width: 100%;

  canvas {
    width: 100% !important;
    height: auto !important;
  }
}

.hint-modal {
  .tabs {
    @include space-between-center-v;
    margin-bottom: 12px;
    margin-top: 30px;
    width: 100%;
  }

  .tab {
    background: none;
    width: 25%;
    padding: 12px 2px;
    cursor: pointer;
    border: none;
    border: 1px solid $grayMd;
    border-left: none;

    &.half {
      width: 50%;
    }

    &:last-child {
      border-right:none;
    }

    &:hover {
      background: rgba($grayLt, .6);
    }

    &.active {
      font-weight: bold;
      border-bottom-color: transparent;
    }
  }

  .tabs.tabs-protocol {
    .tab {
      background: rgba(223, 230, 233, 0.6);
    }
    .tab.active,
    .tab:hover {
      background: transparent;
    }
  }

  &__instruction {
    text-align: center;

    &-row {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin-top: 10px;
    }

    &-col {
      width: 100%;
      text-align: initial;
      background-color: rgba(132, 132, 132, 0.2);
      padding: 10px 20px;
    }

    ul {
      margin: 10px 0 5px 10px!important;
    }

    li, p {
      font-size: 18px;
      &::marker {
        margin-right: 10px;
      }
    }

    p {
      padding: 10px;
    }
  }

  .h4 {
    color: #848484;
    font-size: 30px;
    font-size: 24px;
    margin: 0;
  }

  .h3 {
    color: #848484;
    font-size: 18px;
    display: inline-block;

    &.text--orange {
      margin: 0;

      &.h3--margin-10 {
        margin: 10px 0;
      }
    }
  }

  .underlined {
    border-bottom: 2px solid #f58220;
  }
}

.w-100 {
  width: 100%;
}
