.main {
  position: relative;
  background-color: $grayBg;

  .map {
    display: flex;
    overflow-x: scroll;
    height: calc(100vh - 52px);
    position: relative;
    padding: 0 20px;

    .container {
      &--workout {
        margin-right: 15px;
        height: calc(100% - 21px);
        margin-bottom: 0;
      }

      margin: 0 15px 26px 0;
      height: calc(100% - 26px);
      @include column;

      flex-shrink: 0;

      .h-group {
        height: 24px;
        margin: 16px 0;
        flex-shrink: 0;

        @include space-between-center-v;

        .h4 {
          margin: 0;

          .user-name {
            margin-left: 6px;
          }

          .span {
            &:after {
              font-size: 18px;
              font-weight: bold;

              margin-left: 6px;

              content: '::';
            }
          }
        }
      }

      &_assumptions .col-group {
        height: calc(100vh - 144px);
      }

      .col-group {
        display: flex;
        height: calc(100% - 56px);

        & > .col {
          box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
          padding-left: 0;

          &:not(:last-of-type) {
            margin-right: 9px;
          }
        }
      }
    }

    &::-webkit-scrollbar {
      height: 6px;

      background: rgba($white, .1);
    }

    &::-webkit-scrollbar-thumb {
      width: 6px;

      background-color: rgba($grayDr, .4);
    }

    &::-webkit-scrollbar-track {
      height: 6px;

      background: rgba($subWhite, .5);
    }
  }
}
