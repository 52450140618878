.app-panel {
  background-color: #fff;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  min-width: 465px;
  min-height: 250px;
  
  &--pads {
    padding: 50px;
  }

  & > .ant-spin {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .ant-row {
    margin-bottom: 20px;
  }

  .ant-table-title {
    padding: 0;
  }

  &--password {
    width: 700px;
  }
}

.app-panel.ant-card,
.form-panel.ant-card {
  & > .ant-card-actions {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;

    li {
      width: auto !important;
      float: none;
      margin: 0;
    }

    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.app-panel.ant-card {
  & > .ant-card-actions {
    padding: 20px;
  }
}
.form-panel.ant-card {
  & > .ant-card-actions {
    padding: 10px;
  }
}
