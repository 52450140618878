.button {
  cursor: pointer;

  border: none;
  outline: none;

  appearance: none;

  &--role-print {
    color: $orange;
    background-color: transparent;
    border-bottom: 0 !important;
    font-size: 19px;
    padding: 0 5px;
  }

  .anticon {
    margin-right: 10px;
  }

  &--size-full {
    width: 100%;
    padding: 6px;
    display: block;
  
    background-color: $white;
  
    cursor: pointer;
    transition: .2s ease;
    text-align: left;
  }

  &--role {
    &-nav {
      padding: 0;

      color: $white;
      background-color: transparent;
    }

    &-add {
      color: $grayDm;
      background-color: transparent;
      padding-left: 14px;
    }

    &-print {
      margin-right: 10px;
      margin-left: 10px;
      transition: .2s ease;
      background-color: transparent;
      color: white;

      &:hover {
        color: $orange;
      }
    }

    &-feedback {
      margin-left: 12px;
      padding: 2px 12px;

      transition: .2s ease;

      color: $black;
      background-color: $white;

      &:hover {
        background-color: $grayLt;
      }
    }

    &-protocol-modal {
      margin-right: 12px;
      padding: 2px 12px;

      transition: .2s ease;

      color: $black;
      background-color: $white;

      &:hover {
        background-color: $grayLt;
      }
    }

    &-experiment {
      color: $grayDm;
      background-color: rgba(238, 238, 238, .5);
      border: 2px solid $grayLt;
      margin: 4px 0 5px 18px;
      padding: 6px;
      width: auto;
      min-width: 100px;
      text-align: center;
      display: inline-block;

      &:hover {
        background-color: rgba(238, 238, 238, 1);
      }
    }

    &-submit {
      color: $grayDr;
      background: $grayLt;
      padding: 4px 10px;
      border: 2px solid transparent;
      transition: .2s ease;

      &:hover {
        background: $grayMd;
      }
    }

    &--orange {
      background: $orange;
      color: $white;

      &:hover {
        background: $orange;
      }
    }
  }
}
