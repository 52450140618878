.design-experiments {
  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 6px 26px 0 16px;
    background-color: $white;
    border-bottom: 2px solid $gray;

    .fa-icon_brown {
      display: grid;
      place-items: center;
      position: absolute;
      top: 0;
      right: 0;
      padding: 6px;
      width: 28px;
      height: 28px;
      cursor: pointer;
    }
  }
  &__title {
    min-width: 100px;
  }
  &__center {
    justify-content: center;
    align-items: center;
  }
  &__input {
    border-bottom: 1px solid $grayDm;
    margin: 8px 0;
  }
}

.experiment-buttons {
  display: flex;
  background-color: $white;

  .fa-icon {
    position: absolute;
    top: 25px;
    left: calc(50% - 7px);
    font-size: 12px;
  }

  .button {
    position: relative;
    width: 20px;
    border-left: 2px solid $gray;

    &__text {
      position: absolute;
      top: 50px;
      right: 0;
      left: 0;
      display: block;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 0.55;
      color: $dark;
      transform: rotate(90deg);
      white-space: nowrap;

      .text--orange {
        position: inherit;
        right: inherit;
        top: 0;
        left: 69px;
        transform: rotate(-90deg) translate(-4px, 6px);
        font-size: 14px;
      }
      .text--column-mini {
        left: 50px;
      }
    }
  }

  &_left .button {
    border-left: 0;
    border-right: 2px solid $gray;
  }
  &_small .button {
    border-left: 0;
    border-right: 1px solid $gray;
  }
}

.experiment--single {
  position: relative;
  padding-top: 18px;
  width: 320px;
  height: 100%;
  overflow-x: hidden;
  background-color: $white;

  &--xxl {
    width: 640px;
  }

  &--xl {
    width: 480px;
  }

  &.active {
    background-image: linear-gradient(
      180deg, 
      rgba(0, 0, 0, 0.030) 0%, 
      rgba(0, 0, 0, 0.015) 4px, 
      rgba(0, 0, 0, 0.015) calc(100% - 3px), 
      rgba(0, 0, 0, 0.030) 100%
    );

    .col-experiment {
      background-image: linear-gradient(
        180deg, 
        rgba(0, 0, 0, 0.030) 0%, 
        rgba(0, 0, 0, 0.015) 4px, 
        rgba(0, 0, 0, 0.015) calc(100% - 3px), 
        rgba(0, 0, 0, 0.030) 100%
      );
    }
  }

  &:not(:last-of-type) {
    border-right: 2px solid $grayOrange;
    
  }

  .experiment-letter {
    position: absolute;
    top: 0;
    left: 0;
    padding: 12px 4px 4px 22px;
    font-size: 10px;
    font-weight: 900;
    text-transform: uppercase;
    color: $dark;
    background-color: $warmWhite;
    z-index: 1;
    box-shadow: 0.5px 0.5px 1px 1px rgba(0, 0, 0, 0.05);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-right: 33px solid transparent;
      border-top: 33px solid $lightWhite;
      z-index: -1;
    }
  }

  .experiment-controls {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
  }

  .fa-icon_brown {
    place-items: center;
    padding: 6px;
    z-index: 1;
    cursor: pointer;

    &:hover {
      background-color: $subWhite;
    }
  }

  .icon--trash {
    color: $brownLight;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: auto;
    background-color: $black;
  }

  &::-webkit-scrollbar-track {
    width: 4px;
    height: auto;
    background: $subWhite;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: $grayMd;
  }

  .scrollable {
    &::-webkit-scrollbar {
      width: 4px;
      height: auto;
      background-color: $black;
    }
  
    &::-webkit-scrollbar-track {
      width: 4px;
      height: auto;
      background: $subWhite;
    }
  
    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: $grayMd;
    }
  }
}
