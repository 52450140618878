.dashboard-table {
  .ant-table {
    table {
      border-radius: 0;
    }
    .ant-table-thead {
      &> tr:first-child > th:last-child, &> tr:first-child > th:first-child  {
        border-radius: 0;
      }
      &> tr {
        &> th {
          background: $white;
          font-weight: bold;
        }
      }
    }
    tbody.ant-table-tbody {
        background: $white;

        .ant-table-row {
          cursor: auto;
        }
    }
  }
  .ant-pagination-item, .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    border-radius: 0;
  }

  &-maps {
    .ant-table .ant-table-thead > tr > th,
    .ant-table .ant-table-thead > tr > td {
      background-color: #fafafa !important;
    }
  }
}

.ant-btn-primary, .ant-btn, button, html [type="button"], [type="reset"], [type="submit"] {
  border-radius: 0;
  box-shadow: none;
}
.ant-btn > i, .ant-btn > span {
  text-shadow: none;
}

.new-sprint-modal {
  .ant-modal-content {
    border-radius: 0;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
  }
  .ant-picker {
    width: 100%;
    border-radius: 0;
  }
  .ant-form-item-control-input-content {
    input {
      border-radius: 0;
    }
  }
  .request-description {
    padding: 0 0 1.8rem;
  }
}

.dashboard-sprints {
  @media (max-width: 1024px) {
    margin-top: 45px;
  }
}

.sprint-row {
  background-color: #e2e2e2;
}
