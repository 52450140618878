[dir="rtl"] {
  .main .map .container--workout {
    margin-right: 0;
  }
  .main .map .container .col-group > .col:not(:last-of-type) {
    margin-left: 9px;
    margin-right: 0;
  }

  .experiments .experiment::before {
    right: 5px;
    left: initial;
  }

  .main .map .container .col__item:hover .fa-icon-group, .main .map .container .col__item.active-input .fa-icon-group {
    left: 2px;
    right: initial;
    justify-content: flex-start;
  }

  .main .map .container .col__item:hover .fa-icon-group .delete-icon, .main .map .container .col__item.active-input .fa-icon-group .delete-icon {
    left: 0;
    right: inherit;
  }

  .map .fa-icon-group .modal {
    left: 3px;
    right: initial;
  }

  .experiments .experiment .col__item {
    padding-right: 30px;
    padding-left: 18px;
  }

  .hint-modal__content .svg-inline--fa {
    transform: rotateY(180deg);
    margin-left: 10px;
    margin-right: 0;
  }
  .hint-modal__content .svg-inline--fa[data-icon="check"] {
    transform: rotateY(0deg);
  }

  .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
    text-align: right;
  }
  
  .button--role-add {
    text-align: right;
  }

  .experiment--single .experiment-letter {
    right: 0;
    left: initial;
    padding: 12px 22px 4px 4px;

    &:before {
      transform: rotateY(180deg);
    }
  }

  .experiment--single .experiment-controls {
    left: 0;
    right: initial;
  }

  .experiment-buttons .button__text {
    top: 120px;
  }

  .header__language-selector {
    margin-left: 0 !important;
    margin-right: 12px !important;
  }

  .ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane:nth-child(2).ant-tabs-tabpane-active {
    margin-right: -100%;
  }
  .ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane:nth-child(3).ant-tabs-tabpane-active {
    margin-right: -200%;
  }

  .hint-modal__box .close-icon {
    left: 10px;
    right: initial;
  }

  .gap-left--sm {
    margin-left: 0;
    margin-right: 5px;
  }

  .header .right .header__list-item {
    margin-right: 0;
    margin-left: 12px;
  }

  .select-group:after {
    left: 10px;
    right: initial;
  }

  .select-group .select {
    padding: 2px 10px 2px 24px;
    margin-left: initial;
    margin-right: 10px;
  }

  .workout-plank + .workout-plank {
    margin-left: 0;
    margin-right: 9px;
  }

  .overlay .modal .close-icon {
    left: 8px;
    right: initial;
  }

  .header .right .header__list-item.header__submenu {
    margin-right: 0;
    margin-left: 20px;
  }

  .header__submenu:after {
    left: -10px;
    right: initial;
  }

  .text--size-m,
  .textarea {
    font-size: 16px !important;
  }

  .superscript:after {
    left: -10px;
  }

  * {
    font-style: normal !important;
  }

  .experiment-buttons .button__text .text--orange {
    right: 69px;
    left: 0;
    transform: rotate(-90deg) translate(6px, -4px);
  }

  .header .left .text--weight-bold:after {
    padding-right: 11px;
    padding-left: 0;
  }

  .experiment-wrapper:not(:last-of-type) {
    margin-left: 9px;
    margin-right: 0;
  }
}

.ant-tabs-rtl {
  &.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane:nth-child(2).ant-tabs-tabpane-active {
    margin-right: -100%;
  }
  &.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane:nth-child(3).ant-tabs-tabpane-active {
    margin-right: -200%;
  }
}