.pdf {
  &__container {
    display: flex;
    height: 550px;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
    padding: 10px 20px;

    &.no-gap {
      padding: 0;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: auto;

      background-color: $black;
    }

    &::-webkit-scrollbar-track {
      width: 6px;
      height: auto;

      background: $subWhite;
    }

    &::-webkit-scrollbar-thumb {
      width: 6px;

      background: $grayMd;
    }

    &__load {
      margin-top: 1em;
      color: white;
    }

    &__document {
      margin: 1em 0;

      .react-pdf {
        &__Document {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        &__Page {
          max-width: calc(100% - 2em);
          box-shadow: 0 0 8px rgba(0, 0, 0, .5);
          margin: 1em;

          canvas {
            max-width: 100%;
            height: auto !important;
          }
        }

        &__message {
          padding: 20px;
          color: white;
        }
      }
    }
  }
  &__container-full {
    margin: 0;
    padding: 0 20px;
    &::-webkit-scrollbar {
      background-color: transparent;
    }
  }

  &_full {
    height: 100%;
    
    .pdf__container-full {
      height: 100%;
    }
  }
}
