.map, .dashboard {
  .modal {
    position: absolute;
    z-index: 20;
    bottom: 0;
    right: 25px;
    min-height: 50px;
    display: none;

    padding: 6px;

    border-radius: 3px;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba($grayMd, 1);

    @include center-h-v;
    @include column;

    &.top {
      bottom: initial;
      top: 20px;
    }

    &--wide {
      width: 280px;
    }

    .buttons {
      margin: 10px 0 0;

      @include space-between;

      .button {
        font-size: 12px;

        width: 50px;
        margin: 0;
        margin-left: 4px;
        padding: 0 12px;

        text-align: center;
      }
    }
  }
}

.modal {
  background: $white;
}

.overlay {
  .modal.modal-adaptive {
    height: auto;
  }
}