.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include center-h-v;
  background: rgba($black, .2);
  z-index: 10;

  .modal {
    height: auto;
    padding: 96px 12px 12px;
    box-shadow: 2px 2px 2px 0 rgba($black, .2);
    position: relative;

    .tabs {
      position: absolute;
      left: 0;
      top: 45px;
      @include space-between-center-v;
      margin-bottom: 12px;
      width: 100%;
    }

    .tab {
      background: none;
      width: 25%;
      padding: 12px 2px;
      cursor: pointer;
      border: none;
      border: 1px solid $grayMd;
      border-left: none;

      &.half {
        width: 50%;
      }

      &:last-child {
        border-right:none;
      }

      &:hover {
        background: rgba($grayLt, .6);
      }

      &.active {
        font-weight: bold;
        border-bottom-color: transparent;
      }
    }

    .close-icon {
      font-size: 20px;
      cursor: pointer;
      position: absolute;
      top: 8px;
      right: 8px;
    }

    .col__title {
      @include space-between-center-v;

      .h4 {
        margin-left: 0 !important;
        font-size: 20px;
      }
    }

    &__description {
      margin-top: 10px;
      margin-bottom: 20px !important;
      margin-left: 0;
    }

    .button {
      width: 120px;
    }

    .label {
      margin: 6px 0;
      user-select: none;

      &-red {
        color: $red;
      }
    }

    .link {
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }

    .success-message {
      margin-bottom: 4px;
    }

    .buttons-wrapper {
      @include space-between;
      margin-top: 8px;
    }

    .checkbox {
      margin: 0 4px 0 0;
    }

    .col__item {
      width: 100%;
      margin-bottom: 4px;
    }

    .col__cell {

      &:last-child {
        padding-bottom: 0;
      }

      .h4 {
        font-size: 14px;
      }

      .col__radios {
        @include space-between-center-v;
        margin: 6px 0;
      }
    }
  }

  .protocol-modal {
    padding: 30px 12px 12px;
    position: fixed;
    top: 26px;
    height: calc(100vh - 52px);
    overflow-y: scroll;
    z-index: 100;

    &-heading.col__title {
      justify-content: center;
      
      .h4 {
        font-size: 30px;
        font-weight: 500;
        border-bottom: 2px solid $orange;
        color: #848484;
        margin-bottom: 10px;
      }
    }

    &-numeration {
      font-size: 20px;
      color: $orange;
    }

    &-copyright {
      text-align: center;
      font-size: 12px;
      margin: 12px 0;
    }

    &-step-content {
      padding: 5px 10px;
      font-size: 15px;
      font-style: italic;

      &-text {
        line-height: 20px;
        padding-left: 35px;
      }

      &-heading {
        color: $orange;
        font-size: 18px;
        font-style: normal;
        padding-left: 15px;
        margin: 5px 0;
      }
    }

    &__with-tabs {
      padding-top: 110px;
    }

    &-step.col__title {
      justify-content: initial;
      font-weight: bold;
      display: flex;
      align-items: flex-start;
      width: 100%;

      .h4 {
        font-size: 20px;
      }
    }

    &-title {
      width: 100%;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: auto;
      background-color: #000;
    }

    &::-webkit-scrollbar-thumb {
        width: 6px;
        background: #b2bec3;
    }

    &::-webkit-scrollbar-track {
        width: 6px;
        height: auto;
        background: #f3f3f3;
    }
  }
}
