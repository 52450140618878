.main .map .container {
  .col__item:hover, .col__item.active-input {
    cursor: pointer;

    border-color: $grayLt;

    .fa-icon-group {
      @include column;
      top: 5px;
      right: 3px;
      bottom: 5px;
      justify-content: space-between;
      background: transparent;

      .delete-icon {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }

  .col__button:hover {
    color: $grayDr;
    text-decoration: underline;
  }
}
