.hint-modal {
  &__box {
    position: absolute;
    left: calc(50% - 300px);
    top: 100px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    background: white;
    padding: 20px;
    box-shadow: 2px 2px 9px 2px rgba(0, 0, 0, 0.2);
    width: 600px;

    &--wide {
      width: 800px;
      left: calc(50% - 400px);
    }

    &--note {
      width: 930px;
      left: calc(50% - 465px);
    }

    &--video {
      width: 660px;
      left: calc(50% - 330px);
    }

    &--large {
      width: 1050px;
      left: calc(50% - 525px);
    }

    &--medium {
      width: 700px;
      left: calc(50% - 350px);
      padding-bottom: 0;
    }

    .close-icon {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }
  }

  &__protocol.hint-modal__box {
    top: 26px;
    height: calc(100vh - 100px);
    padding: 20px 0 0 0;
  }

  &__dragger {
    cursor: grab;
    width: 16px;
    position: absolute;
    left: 20px;
    top: 20px;
  }

  &__examples-title {
    display: block;
    margin-bottom: 10px;
    font-style: italic;

    &--small {
      font-size: 12px;
    }
  }

  &__header {
    font-size: 30px;
    font-weight: 500;
    border-bottom: 2px solid $orange;
    color: #848484;
    text-align: center;
    margin-bottom: 10px;
  }

  &__controlls {
    height: 10px;
  }

  &__column {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    width: 50%;

    &--full {
      width: 100%;
    }

    &:nth-child(even),
    &--full {
      margin-right: 0;
    }
  }

  &__instructions {
    text-align: center;
    font-style: italic;
    line-height: 30px;
  }

  &__guideline {
    text-align: center;
    margin-bottom: 15px;
  }

  &__example {
    display: flex;
    margin-bottom: 20px;
  }

  &__content {
    border: 1px solid #848484;
    margin-bottom: 10px;
    background-color: rgba($color: #848484, $alpha: .2);
    padding: 10px;
    width: 100%;
    height: 100%;

    .svg-inline--fa {
      color: $orange;
      margin-right: 10px;
    }

    &-title {
      font-size: 18px;
      font-style: italic;
      margin-bottom: 10px;
      color: #848484;
    }
  }
}
