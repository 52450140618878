.map {
  .notes {
    position: absolute;
    z-index: 4;
    bottom: 20px;
    left: 10px;

    display: none;

    max-height: 382px;

    border-radius: 3px;
    background-color: $subWhite;
    box-shadow: 0 2px 2px 0 rgba($grayMd, .8);

    .col {
      max-height: 300px;

      background-color: $subWhite;

      &__content {
        max-height: 300px;
      }

      &__item,
      .textarea {
        background-color: $grayLt;
      }

      &__button {
        background-color: $grayLt;

        &:hover {
          background-color: $grayMd;
        }
      }
    }
  }
}
.workout-notes {
  position: relative;
  margin-top: 10px;
  padding: 5px;
  &__title {
    margin-top: 5px;
    font-weight: bold;
    font-size: 18px;
  }
  
  .col__item {
    border: 2px solid $grayNick;
    margin-bottom: 5px;
    padding: 6px 6px 6px 10px;

    &.active-input {
      padding: 6px 6px 6px 10px;
      border: 2px solid transparent;
      background: $white;
      width: 100%;
      border-color: $grayLt;
      padding: 6px 6px 4px 10px;
      box-shadow: none;
    }
  }

  .modal {
    right: 20px;
  }
}

.workout-entry.active-input {
  border: 2px solid $grayNick;
  margin-bottom: 5px;
  padding: 6px 6px 6px 10px;
  padding: 6px 6px 6px 10px;
  border: 2px solid transparent;
  background-image: none !important;
  background: none;
  mix-blend-mode: none;
  width: 100%;
  border-color: $grayLt;
  padding: 6px 6px 4px 10px;

  &:hover {
    background-image: transparent;
    background: transparent;
  }
}
